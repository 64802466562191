/* Roboto Bold Regular reference */
@font-face {
     font-family: 'Roboto';
     font-style: normal;
     font-weight: 400;
     src: local(''), url('./assets/fonts/Roboto-Regular.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* Roboto Medium reference */
@font-face {
     font-family: 'Roboto';
     font-style: bold;
     font-weight: 500;
     src: local(''), url('./assets/fonts/Roboto-Medium.ttf') format('truetype'); /* Safari, Android, iOS */
}

/* oswald-300 - latin */
@font-face {
     font-family: 'Oswald';
     font-style: normal;
     font-weight: 300;
     src: url('./assets/fonts/oswald-v36-latin-300.eot'); /* IE9 Compat Modes */
     src: local(''), url('./assets/fonts/oswald-v36-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('./assets/fonts/oswald-v36-latin-300.woff2') format('woff2'),  /* Super Modern Browsers */
          url('./assets/fonts/oswald-v36-latin-300.woff') format('woff'), /* Modern Browsers */
          url('./assets/fonts/oswald-v36-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
          url('./assets/fonts/oswald-v36-latin-300.svg#Oswald') format('svg'); /* Legacy iOS */
}

/* oswald-regular - latin */
@font-face {
     font-family: 'Oswald-Regular';
     font-style: normal;
     font-weight: 400;
     src: url('./assets/fonts/oswald-v36-latin-regular.eot'); /* IE9 Compat Modes */
     src: local(''), url('./assets/fonts/oswald-v36-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('./assets/fonts/oswald-v36-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
          url('./assets/fonts/oswald-v36-latin-regular.woff') format('woff'), /* Modern Browsers */
          url('./assets/fonts/oswald-v36-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
          url('./assets/fonts/oswald-v36-latin-regular.svg#Oswald') format('svg'); /* Legacy iOS */
}